<template>
  <v-container>
    <expense-list/>
  </v-container>
</template>

<script>
import ExpenseList from "@/components/app_setting/expense/ExpenseList";

export default {
  name: "ExecutionTypePage",
  components: {
    ExpenseList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Expenses'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>